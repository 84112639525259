<template>
    <nut-actionsheet :is-visible="visible" @close="closeActionSheet">
        <div class="nut-picker" slot="custom" :class="customClassName ? customClassName : null">
            <div class="nut-picker-control">
                <span class="nut-picker-cancel-btn" @click="closeActionSheet">{{ $t('TITLE.CANCEL') }}</span>
                <div class="nut-picker-title">{{ title ? title : '' }}</div>
                <span class="nut-picker-confirm-btn" style="min-width: 60px; width: auto;"
                      @click="confirm">{{ $t('TITLE.CONFIRM') }}</span>
            </div>
            <div class="nut-picker-panel">
                <template v-for="(item, index) of listData">
                    <nut-picker-slot
                        :ref="`picker-slot-${index}`"
                        :default-value="chooseValueData[index]"
                        :is-update="isUpdate"
                        :list-data="item"
                        @chooseItem="chooseItem"
                        :key="index"
                        :key-index="index"
                    ></nut-picker-slot>
                </template>
            </div>
        </div>
    </nut-actionsheet>
</template>
<script>
import NutPickerSlot from '@/components/nut-new-pick-slot';

export default {
    name: 'nut-picker',
    mixins: [],
    props: {
        isVisible: {
            type: Boolean,
            default: false
        },
        customClassName: {
            type: String,
            default: null
        },
        title: {
            type: String,
            default: ' '
        },
        listData: {
            type: Array,
            default: () => []
        },
        defaultValueData: {
            type: Array,
            default: () => []
        }
    },
    components: {
        NutPickerSlot
    },
    data() {
        return {
            chooseValueData: [],
            cacheValueData: [],
            isUpdate: false,
            visible: this.isVisible,
        };
    },
    watch: {
        defaultValueData() {
            this.chooseValueData = [...this.defaultValueData];
            this.cacheValueData = [...this.defaultValueData];
        },
        listData: function () {
            this.init();
        },
        isVisible(val) {
            this.visible = val;
        }
    },
    methods: {
        updateChooseValue(self, index, value) {
            self.cacheValueData.splice(index, 1, value);
            let ref = `picker-slot-${index}`;
            self.$refs[ref] && self.$refs[ref][0].updateTransform(value);
        },

        closeActionSheet() {
            this.isUpdate = !this.isUpdate;
            this.cacheValueData = [...this.chooseValueData];
            this.$emit('close');
            this.$emit('close-update', this, this.chooseValueData);
            this.visible = false;
            this.$emit('update:isVisible', this.visible);
        },

        confirm() {
            this.$emit('confirm', this.cacheValueData);
            this.chooseValueData = [...this.cacheValueData];
            this.visible = false;
            this.$emit('update:isVisible', this.visible);
        },
        chooseItem(value, index) {
            if (this.cacheValueData[index] !== value) {
                this.cacheValueData[index] = value;
                this.$emit('choose', this, index, value, this.cacheValueData);
            }
        },
        init() {
            if (this.defaultValueData && this.defaultValueData.length) {
                this.chooseValueData = [...this.defaultValueData];
            } else {
                let defaultValueData = [];
                this.listData.map((item) => {
                    defaultValueData.push(item[0]);
                });
                this.chooseValueData = [...defaultValueData];
            }
        }
    },

    created() {
        this.init();
    }
};
</script>
<style lang="scss">
.nut-picker-confirm-btn, .nut-picker-cancel-btn {
    color: $--public-highlight !important;
}
</style>
