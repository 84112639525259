import Vue from 'vue';

import "./nutui.scss"
import Button from '@nutui/nutui/dist/packages/button/button.js';  // 加载构建后的JS
import ButtonGroup from '@nutui/nutui/dist/packages/buttongroup/buttongroup';
import TextInput from '@nutui/nutui/dist/packages/textinput/textinput';
import Toast from '@nutui/nutui/dist/packages/toast/toast';
import Dialog from '@nutui/nutui/dist/packages/dialog/dialog';
import Icon from  '@nutui/nutui/dist/packages/icon/icon';
import Swiper from '@nutui/nutui/dist/packages/swiper/swiper';
import Popup from '@nutui/nutui/dist/packages/popup/popup';
import Cell from '@nutui/nutui/dist/packages/cell/cell';
import Picker from '@/components/nut-new-picker';
import ShortPassword from '@/components/nut-new-short-password';
import Actionsheet from '@nutui/nutui/dist/packages/actionsheet/actionsheet';
import Infiniteloading from '@nutui/nutui/dist/packages/infiniteloading/infiniteloading';
import TextBox from '@nutui/nutui/dist/packages/textbox/textbox'
import Video from '@/components/nut-new-video'

Button.install(Vue);
ButtonGroup.install(Vue);
TextInput.install(Vue);
Toast.install(Vue);
Dialog.install(Vue);
Icon.install(Vue);
Swiper.install(Vue);
Popup.install(Vue);
Cell.install(Vue);
Actionsheet.install(Vue);
Infiniteloading.install(Vue);
TextBox.install(Vue);

Vue.component('nut-picker',Picker);
Vue.component('nut-shortpassword',ShortPassword);
Vue.component('nut-video',Video);
