<template>
    <div class="nut-short-pwd inline">
            <div class="nut-layer">
                <div class="nut-input-w">
                    <input
                        type="tel"
                        ref="realInput"
                        class="nut-real-input"
                        maxlength="6"
                        autocomplete="off"
                        autofocus="autofocus"
                        v-model="shortpwdValue"
                        @input="shortpwdInput($event)"
                    />
                    <div class="nut-fake-list" ref="nutFakeList">
                        <ul>
                            <li v-for="item in fakeInputItems" :key="item.id">
                                <input type="password" maxlength="1" v-model="item.val" />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
    </div>
</template>

<script>
export default {
    name: 'nut-shortpassword',
    mixins: [],
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        link: {
            type: String,
            default: 'javascript:;'
        },
        type: {
            type: String,
            default: 'bottom'
        }
    },
    data() {
        return {
            shortpwdValue: '',
            regExp: /^[0-9]*$/,
            // fakeCursorStyle:{
            // 	display:'none',
            // 	left:'0px'
            // },
            fakeInputItems: [
                {
                    id: 1,
                    val: ''
                },
                {
                    id: 2,
                    val: ''
                },
                {
                    id: 3,
                    val: ''
                },
                {
                    id: 4,
                    val: ''
                },
                {
                    id: 5,
                    val: ''
                },
                {
                    id: 6,
                    val: ''
                }
            ],
            timer: null,
            show: false
        };
    },
    methods: {
        shortpwdInput(e) {
            let val = e.target.value;
            if (this.regExp.test(val)) {
                //位移假光标
                if (val.length < 6) {
                    //let num = this.$refs.nutFakeList.offsetLeft + 26;
                    //let left = val.length * 54 + num;
                    // this.fakeCursorStyle = {
                    // 	'left':left+'px',
                    // 	'display':'block'
                    // }
                } else {
                    // this.fakeCursorStyle = {
                    // 	'display':'none'
                    // }
                    this.$refs.realInput.blur();
                    const vm = this;
                    setTimeout(() => {
                        //如果是6位输入满，将直接关闭弹层，给出输入的值
                        vm.$emit('done', val);
                        vm.shortpwdValue = '';
                        vm.fakeInputItems = [
                            {
                                id: 1,
                                val: ''
                            },
                            {
                                id: 2,
                                val: ''
                            },
                            {
                                id: 3,
                                val: ''
                            },
                            {
                                id: 4,
                                val: ''
                            },
                            {
                                id: 5,
                                val: ''
                            },
                            {
                                id: 6,
                                val: ''
                            }
                        ];
                    },300);
                }
                for (let i = 0; i < val.length; i++) {
                    this.fakeInputItems[i].val = val[i];
                }
            } else {
                //清空非数字
                this.shortpwdValue = val.replace(/\D/g, '');
            }

            //删除操作的时候同步假密码框
            for (let i = 0; i < 6; i++) {
                if (i >= val.length) {
                    this.fakeInputItems[i].val = '';
                }
            }
        }
    },
    computed: {},
    mounted() {},
};
</script>
